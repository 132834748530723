// Here you can add other styles
body {
	font-size: 12px !important;
}
.custom-select-lg {
    font-size: 0.8rem !important;
}

h2.ant-typography, .ant-typography h2 {
    font-size: 25px !important;
}

.btn {   
    padding: 0.25rem 0.5rem !important;
    font-size: 0.75rem !important;
    line-height: 1.5 !important;  
}
.ant-tree {
    font-size: 13px !important;
}
.table-responsive > table > tbody > tr > td > div > button > div > svg {
    width: 0.8rem !important;
    height: 0.8rem !important;
}
.ant-modal {
    margin-top: 2% !important;
}

.btnAltaTabla {
    /*position: absolute;
    right: 20px;
    z-index:1;*/
}

.table-responsive table{
    background-color: #fff;
}

h6.card-subtitle strong {
    width: 15em;
    display: inline-block;
}

@media only screen and (max-width: 576px){
    .btnAltaTabla {
        position: relative;
    }
}

.antd-select-multiple {
    width: 100%;
    border-radius: 4px;
}

.ant-modal-wrap {
    z-index: 10000 !important;
}
